import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { QrCodeComponent } from "../modules/core/qr-code/qr-code.component";
import { EQrCodeType } from "../enums/eqr-code-type";
import { QrCodeCredentials, QrCodeTestDevice } from "../entities/qr-code-credentials";
import { Credentials } from "../entities/credentials";

@Injectable({
	providedIn: "root",
})
export class QrCodeService {
	qrCodeDialog: MatDialogRef<QrCodeComponent> = null;

	constructor(private dialog: MatDialog) {}

	generateWifiQrCode(ssid: string, password: string, type: string): void {
		const qrCode = `WIFI:T:${type};S:${ssid};P:${password};;`;
		this.openDialog(qrCode);
	}

	generateTestGlassesProtocolQrCode(protocolId: number): void {
		const code: QrCodeTestDevice = {
			type: EQrCodeType.TEST_DEVICE,
			version: 1,
			protocolId: protocolId,
		};
		const data = JSON.stringify(code);
		this.openDialog(data);
	}

	generateCredentialsQrCode(credentials: Credentials): void {
		const code: QrCodeCredentials = {
			type: EQrCodeType.CREDENTIALS,
			version: 1,
			username: credentials.username,
			password: credentials.password,
		};
		const data = JSON.stringify(code);
		this.openDialog(data);
	}

	private openDialog(content: string): void {
		this.closeDialog();
		this.qrCodeDialog = this.dialog.open(QrCodeComponent, {
			data: {
				content: content,
			},
			disableClose: true,
			autoFocus: false,
		});
	}

	private closeDialog(): void {
		if (this.qrCodeDialog) {
			this.qrCodeDialog.close();
			this.qrCodeDialog = null;
		}
	}
}
