<div
	mat-dialog-title
	[align]="'end'">
	<button
		mat-icon-button
		mat-dialog-close>
		<mat-icon>close</mat-icon>
	</button>
</div>
<div mat-dialog-content>
	<qrcode
		[qrdata]="qrContent"
		[allowEmptyString]="true"
		[cssClass]="'center'"
		[colorDark]="'#000000ff'"
		[colorLight]="'#ffffffff'"
		[elementType]="'canvas'"
		[errorCorrectionLevel]="'M'"
		[imageSrc]="'./assets/images/mitp.webp'"
		[imageHeight]="75"
		[imageWidth]="75"
		[margin]="4"
		[scale]="1"
		[width]="300"></qrcode>
</div>
